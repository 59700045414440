
export const earthBranchContainSkyStem = {
    "子": "癸",
    "丑": "己癸辛",
    "寅": "甲丙戊",
    "卯": "乙",
    "辰": "戊乙癸",
    "巳": "丙戊庚",
    "午": "丁己",
    "未": "己丁乙",
    "申": "庚壬戊",
    "酉": "辛",
    "戌": "戊辛丁",
    "亥": "壬甲"
};

export function getSkyStemFromEarthBranch(earth) {
    return earthBranchContainSkyStem[earth]
}

/**
 * Break Letter address to object for display
 * @param {*} letterAddressString e.g. 甲辰戊辰甲辰壬申戊乙癸戊乙癸戊乙癸庚壬戊
 * @returns Array of letter. The index of letter match with AddressIndex
 * 
 * [ { name: "辰",
    tenGod: {
      originalChiFiveProperty: "土",
      originalChi: "戊",
      originalChiGod: "比",
      middleChiFiveProperty: "木",
      middleChi: "乙",
      middleChiGod: "官",
      reminderChiFiveProperty: "水",
      reminderChi: "癸",
      reminderChiGod: "財",
        },
    },
    ]
 
 */
export function getLetterAddressObj(letterAddressString) {
    let arrLetterAddressObj = []
    const dayStemLetter = letterAddressString[4]
    arrLetterAddressObj[0] = getLetterAddressObjSky(dayStemLetter, letterAddressString[0])
    arrLetterAddressObj[1] = getLetterAddressObjEarth(dayStemLetter, letterAddressString[1],
        letterAddressString[8], letterAddressString[9], letterAddressString[10])
    arrLetterAddressObj[2] = getLetterAddressObjSky(dayStemLetter, letterAddressString[2])
    arrLetterAddressObj[3] = getLetterAddressObjEarth(dayStemLetter, letterAddressString[3],
        letterAddressString[11], letterAddressString[12], letterAddressString[13])
    arrLetterAddressObj[4] = getLetterAddressObjSky(dayStemLetter, letterAddressString[4])
    arrLetterAddressObj[5] = getLetterAddressObjEarth(dayStemLetter, letterAddressString[5],
        letterAddressString[14], letterAddressString[15], letterAddressString[16])
    arrLetterAddressObj[6] = getLetterAddressObjSky(dayStemLetter, letterAddressString[6])
    arrLetterAddressObj[7] = getLetterAddressObjEarth(dayStemLetter, letterAddressString[7],
        letterAddressString[17], letterAddressString[18], letterAddressString[19])
    return arrLetterAddressObj
}


/**
 * The object for displaying 地支 <Letter> for earth branch
 * @param {*} dayStemLetter 
 * @param {*} letter 
 * @param {*} originalChi 
 * @param {*} middleChi 
 * @param {*} reminderChi 
 * @returns 
 */
export function getLetterAddressObjEarth(dayStemLetter, letter, originalChi, middleChi, reminderChi) {
    let letterAddressObj = {}
    letterAddressObj.name = letter
    letterAddressObj.tenGod = {}
    letterAddressObj.tenGod.originalChiFiveProperty = getFiveProperty(originalChi)
    letterAddressObj.tenGod.originalChi = originalChi
    letterAddressObj.tenGod.originalChiGod = get10God(dayStemLetter, originalChi)
    letterAddressObj.tenGod.middleChiFiveProperty = getFiveProperty(middleChi)
    letterAddressObj.tenGod.middleChi = middleChi
    letterAddressObj.tenGod.middleChiGod = get10God(dayStemLetter, middleChi)
    letterAddressObj.tenGod.reminderChiFiveProperty = getFiveProperty(reminderChi)
    letterAddressObj.tenGod.reminderChi = reminderChi
    letterAddressObj.tenGod.reminderChiGod = get10God(dayStemLetter, reminderChi)
    return letterAddressObj
}

/**
 * The object for displaying 天干 on <Letter>
 * @param {*} dayStemLetter 
 * @param {*} letter 
 * @returns 
 */
export function getLetterAddressObjSky(dayStemLetter, letter) {
    let letterAddressObj = {}
    letterAddressObj.name = letter
    letterAddressObj.tenGod = {}
    letterAddressObj.tenGod.originalChiFiveProperty = getFiveProperty(letter)
    //letterAddressObj.tenGod.originalChi = letter
    letterAddressObj.tenGod.originalChiGod = get10God(dayStemLetter, letter)
    return letterAddressObj
}

const mapFiveProperty = {
    "甲": "木",
    "乙": "木",
    "丙": "火",
    "丁": "火",
    "戊": "土",
    "己": "土",
    "庚": "金",
    "辛": "金",
    "壬": "水",
    "癸": "水"
}

export function getFiveProperty(letter) {
    if (letter === undefined)
        return undefined
    else
        return mapFiveProperty[letter]
}

const map10God = {
    "甲": { "甲": "比", "乙": "劫", "丙": "食", "丁": "傷", "戊": "才", "己": "財", "庚": "殺", "辛": "官", "壬": "ㄗ", "癸": "印" },
    "乙": { "甲": "劫", "乙": "比", "丙": "傷", "丁": "食", "戊": "財", "己": "才", "庚": "官", "辛": "殺", "壬": "印", "癸": "ㄗ" },
    "丙": { "甲": "ㄗ", "乙": "印", "丙": "比", "丁": "劫", "戊": "食", "己": "傷", "庚": "才", "辛": "財", "壬": "殺", "癸": "官" },
    "丁": { "甲": "印", "乙": "ㄗ", "丙": "劫", "丁": "比", "戊": "傷", "己": "食", "庚": "財", "辛": "才", "壬": "官", "癸": "殺" },
    "戊": { "甲": "殺", "乙": "官", "丙": "ㄗ", "丁": "印", "戊": "比", "己": "劫", "庚": "食", "辛": "傷", "壬": "才", "癸": "財" },
    "己": { "甲": "官", "乙": "殺", "丙": "印", "丁": "ㄗ", "戊": "劫", "己": "比", "庚": "傷", "辛": "食", "壬": "財", "癸": "才" },
    "庚": { "甲": "才", "乙": "財", "丙": "殺", "丁": "官", "戊": "ㄗ", "己": "印", "庚": "比", "辛": "劫", "壬": "食", "癸": "傷" },
    "辛": { "甲": "財", "乙": "才", "丙": "官", "丁": "殺", "戊": "印", "己": "ㄗ", "庚": "劫", "辛": "比", "壬": "傷", "癸": "食" },
    "壬": { "甲": "食", "乙": "傷", "丙": "才", "丁": "財", "戊": "殺", "己": "官", "庚": "ㄗ", "辛": "印", "壬": "比", "癸": "劫" },
    "癸": { "甲": "傷", "乙": "食", "丙": "財", "丁": "才", "戊": "官", "己": "殺", "庚": "印", "辛": "ㄗ", "壬": "劫", "癸": "比" }
}

/**
 * Look up 十神
 * @param {*} dayStemLetter 日元
 * @param {*} letter 
 * @returns 
 */
export function get10God(dayStemLetter, letter) {
    if (dayStemLetter === undefined || letter === undefined) {
        return undefined
    } else {

        let result = "";

        if (map10God[dayStemLetter] && map10God[dayStemLetter][letter]) {
            result = map10God[dayStemLetter][letter];
        }
        return result;
    }
}

/**
 * Convert mson of letterAddressAllDateString to {letterAddressAllDate:{"string":"xx"},{"details":[xx]}}
 * Add letterAddressAllDate{
 *  "2024-04-24T22:22:00":{
 *    "string":""甲辰戊辰戊午癸亥戊乙癸戊乙癸丁己 壬甲                     子巳",
 *    "details":[
 *      "40|組|子|水|組合|癸6||",
 *      "41|拱|巳|火|拱|辰1;3|午5|",
 *      "|會||火|半會火局|巳41|午5|",
 *      "|會||水|半會水局|亥7|子40|",
 *      "|會||水|半合水局||子40|辰1;3"
 *     ]
 * }
 * 
 * 
 * @param {*} analysisData json of Horoscope VM
 */
export function parseHoroscope(analysisData) {

    let letterAddressAllDate = {};
    //0 is date time
    //1 is string of letter address
    //rest of them are description
    if (analysisData && analysisData.letterAddressAllDateString) {
        let arrNewLine = analysisData.letterAddressAllDateString.split('\n')
        arrNewLine && arrNewLine.forEach(element => {
            //case: loop for each LetterAddressAllDate    

            let arrGraveAccent = element.split('`')

            let arrContent = {}

            let dateTime = arrGraveAccent[0]
            let string = arrGraveAccent[1]
            arrContent["string"] = string
            arrContent["details"] = arrGraveAccent.slice(2) //get from 3rd item to the end

            letterAddressAllDate[dateTime] = arrContent

        });
        //set to object
        analysisData["letterAddressAllDate"] = letterAddressAllDate
    }
    return analysisData

}


/**
 * 拆輸入Input
 * @param {*} input e.g. a string of 丑47;48
  * @param {*} details 
  * @returns {"input":"丑","IndexText":[ 流日地支,拱]}. If out of range, return undefined
  */
export function parseInputLetter(input, details, addressSchema) {

    let result = {}
    let indexText = []
    // let arrEqual = input.split("=")
    if (input.length > 0) {
        result["input"] = input[0];
        let arrSemicolon = input.substring(1).split(";")    //

        const endOfFortuneYearMonth = 39
        arrSemicolon.forEach(i => {
            if (i <= endOfFortuneYearMonth) {
                //case:check whether we can lookup from address schema
                const schema = addressSchema.find(x => x.Index == i)
                indexText.push(schema.pillar + schema.skyearth + (schema.chi && schema.chi != "" ? "之" + schema.chi : ""))
            } else {
                //case:find index number and get action string
                details.forEach(element => {
                    let arrPipe = element.split('|')
                    if (arrPipe[0] == i) {
                        indexText.push(arrPipe[4])
                    }
                });
            }
        });
    }
    result["indexText"] = indexText
    return result
}

/**
 * Parse all combination
 * @param {*} address 
 * @param {*} details 
 * @returns a string of multiple finding for an address, ended with newline
 */
export function parseDetailsOfLetterAddressToText(address, details, addressSchema) {

    let result = "";

    const arrDetailsObj = parseDetailsOfLetterAddressToArray(details, addressSchema)
    arrDetailsObj.forEach(obj => {
        result += (obj.sentence + "\r\n")
    });
    return result;
}

/**
 * parse all combination to array
 * @param {*} details 
 * @param {*} addressSchema 
 * @returns [{msonId:x,sentence:"xxx"}]. If no deviated letter, mson is undefined
 */
export function parseDetailsOfLetterAddressToArray(details, addressSchema) {

    let result = [];
    details.forEach(element => {
        if (element && element.length > 0) {
            let prefixSentence = "";
            let sentence = "";
            // e.g.[
            //     "40",
            //     "組",
            //     "酉",
            //     "金",
            //     "組合",
            //     "辛6",
            //     "",
            //     "",
            //      ""
            //   ]
            let arrPipe = element.split('|')

            //if index is not empty, display new letter
            const combinationType = arrPipe[4] + (arrPipe[0].length > 0 && arrPipe[2].length > 0 ? `[${arrPipe[2]}]` : "")
            sentence = combinationType + "："  //e.g.組合[辰]: 
            const exceptionPrefix = "天克地沖"

            if (arrPipe[5] && arrPipe[5].length > 0) {
                let inputObj = parseInputLetter(arrPipe[5], details, addressSchema)
                if (arrPipe[4] != exceptionPrefix) {    //don't add letter in front of 天克地沖
                    prefixSentence += inputObj["input"]
                }
                sentence += `[${inputObj["input"]}]` + inputObj["indexText"].join(',') + " "
            }

            if (arrPipe[6] && arrPipe[6].length > 0) {
                let inputObj = parseInputLetter(arrPipe[6], details, addressSchema)
                if (arrPipe[4] != exceptionPrefix) {    //don't add letter in front of 天克地沖
                    prefixSentence += inputObj["input"]
                }
                sentence += `[${inputObj["input"]}]` + inputObj["indexText"].join(',') + " "
            }


            if (arrPipe[7] && arrPipe[7].length > 0) {
                let inputObj = parseInputLetter(arrPipe[7], details, addressSchema)
                if (arrPipe[4] != exceptionPrefix) {    //don't add letter in front of 天克地沖
                    prefixSentence += inputObj["input"]
                }
                sentence += `[${inputObj["input"]}]` + inputObj["indexText"].join(',') + " "
            }

            if (arrPipe[8] && arrPipe[8].length > 0) {
                let inputObj = parseInputLetter(arrPipe[8], details, addressSchema)
                if (arrPipe[4] != exceptionPrefix) {    //don't add letter in front of 天克地沖
                    prefixSentence += inputObj["input"]
                }
                sentence += `[${inputObj["input"]}]` + inputObj["indexText"].join(',')
            }

            //this is the description of 刑沖破害
            if (arrPipe[0].length == 0 && arrPipe[2].length > 0) {
                sentence += (" ➔" + arrPipe[2])
            }

            //msonId is arrPipe[0] or  戊癸遇龍則化[丙]
            result.push({ "msonId": arrPipe[0] || prefixSentence + combinationType, "sentence": (prefixSentence + sentence) })
        }
    });
    return result
}



/**
 * Get the content for displaying deviated letters
 * @param {*} details The array of string of mson  
 * @returns {"deviatedLetters":{},"parties":{} }
 */
export function getDeviatedLetters(details) {
    function innerExtractParts(part, seats, arrow) {
        if (part.length > 0) {
            let letter = part[0]
            let indexArr = part.substring(1).split(";")

            for (let i = 0; i < indexArr.length; i++) {
                if (indexArr[i].length > 0) {
                    let index = parseInt(indexArr[i])
                    seats.push(index)
                    arrow[index] = letter
                }
            }
        }
    }

    let result = {};
    result["deviatedLetters"] = {}
    result["parties"] = {}

    for (let i = 0; i < details.length; i++) {
        let parts = details[i].split("|");
        // if (parts[0]) {
        let id = parts[0];
        let action = parts[4];
        let newChar = parts[2];
        let detail = details
        let seats = []
        let arrow = {}

        innerExtractParts(parts[5], seats, arrow);
        innerExtractParts(parts[6], seats, arrow);
        innerExtractParts(parts[7], seats, arrow);

        if (id) {
            result["deviatedLetters"][id] = {
                "id": id,
                "seats": seats,
                "action": action,
                "new": newChar,
                "detail": detail,
                "arrow": arrow
            };
        } else {
            result["parties"][i] = {
                "id": i,
                "seats": seats,
                "action": action,
                "new": newChar,
                "detail": detail,
                "arrow": arrow
            }
        }
        // }
    }
    return result;
}

const skyStemIndexList = [6, 4, 2, 0, 20, 25, 30, 35]
const earthBranchIndexList = [7, 5, 3, 1, 21, 26, 31, 36]


export function getEarthBranchIndexList() {
    return earthBranchIndexList
}

export function getSkyStemIndexList() {
    return skyStemIndexList
}

export function isAllIndexSkyStem(indexs) {
    return indexs.every(element => skyStemIndexList.includes(element));
}

export function isAnyIndexEarthBranch(index) {
    return earthBranchIndexList.includes(index);
}

const skyStemLetterList = ["甲", "乙", "丙", "丁", "戊", "己", "庚", "辛", "壬", "癸"]

export function isSkyStemLetter(letter) {
    return skyStemLetterList.includes(letter)
}

export const getMainText = (selectedLetter, letter) => {
    if (isSkyStemLetter(letter)) {
        return getLetterAddressObjSky(selectedLetter, letter)
    } else {
        const containSky = getSkyStemFromEarthBranch(letter)
        return getLetterAddressObjEarth(selectedLetter, letter, containSky[0], containSky[1], containSky[2])
    }
}