import styles from "./index.module.css"
import { useState, useEffect } from 'react';
import EightLettersToWestenDate from "../EightLettersToWestenDate";
import GenderSelection from "../GenderSelection";
import { globalData } from "../../Helper/global";
import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stars from '@mui/icons-material/Stars';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

export default function ScrollableList(props) {


    return (
        <Box sx={{
            width: '100%',
            maxWidth: '100%',
            bgcolor: 'background.paper',
            maxHeight: '20vh',
            overflow: 'auto'
        }}>
            <div>
                {props.featureList.map((feature, index) => (
                    <div key={"featureList" + index}>
                <Stars color="primary"  fontSize="small"/>{feature}
                    </div>
                ))}
            </div>

        </Box>
    )
}

ScrollableList.propTypes = {
    featureList: PropTypes.any.isRequired
}