import { useState, useEffect } from "react";

export default function useScreenSizeCheck(){
    // const sizeOfSmallScreen = 375;
    //hardcode for mobile
    const sizeOfSmallScreen = 1024;
    
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= sizeOfSmallScreen);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= sizeOfSmallScreen);
    };

    checkScreenSize();

    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  return isSmallScreen;
};
