export let peachBlossomsInfo = {}

const mapPeachBlossoms = {
    "甲": "子",
    "乙": "子",
    "丙": "卯",
    "丁": "卯",
    "戊": "卯",
    "己": "卯",
    "庚": "午",
    "辛": "午",
    "壬": "酉",
    "癸": "酉"
}
/**
 * check 桃花
 * @param {*} selectedLetter 
 * @param {*} selectedLetterIndex 
 * @param {*} earthBranch 
 * @param {*} earthBranchIndex 
 * @returns return { "result": isFlower, "text": returnText }

 */
export function isPeachBlossoms(selectedLetter, selectedLetterIndex, earthBranch, earthBranchIndex) {

    const isFlower = mapPeachBlossoms[selectedLetter] === earthBranch

    peachBlossomsInfo[earthBranchIndex] = true

    //check 牆外桃花
    let returnText = isFlower ? "桃花" : ""
    const outsideFlower = isFlower && (selectedLetterIndex === 4 && earthBranchIndex === 7)
    if (outsideFlower) {
        returnText = "牆外桃花";
    }
    return { "result": isFlower, "text": returnText }

}

const mapStudyHelper = {
    "甲": "己",
    "乙": "午",
    "丙": "申",
    "丁": "酉",
    "戊": "申",
    "己": "酉",
    "庚": "亥",
    "辛": "子",
    "壬": "寅",
    "癸": "卯"
}

/**
 * Check 文昌貴人
 * 日元自坐, 或流年, 大運, 月干等等
 * @param {*} selectedLetter 
 * @param {*} selectedLetterIndex 
 * @param {*} earthBranch 
 * @param {*} earthBranchIndex 
 * @returns return { "result": isStudyHelper, "text": (isStudyHelper ? "文昌貴人" : "") }
 */
export function isStudyHelper(selectedLetter, selectedLetterIndex, earthBranch, earthBranchIndex) {

    const isDayPillar = (selectedLetterIndex + 1 === earthBranchIndex) //check whether selected letter is sitting on it
    const isFortuneYearMonth = !([1, 3, 5, 7].includes(earthBranchIndex))
    const isStudyHelper = (mapStudyHelper[selectedLetter] === earthBranch) && (isDayPillar || isFortuneYearMonth)
    return { "result": isStudyHelper, "text": (isStudyHelper ? "文昌貴人" : "") }

}

const mapHorse = {
    "甲寅": "本命馬", "乙寅": "本命馬", "丙寅": "印馬", "丁寅": "印馬", "戊寅": "殺馬", "己寅": "官馬",
    "丙巳": "本命馬", "丁巳": "本命馬", "戊巳": "本命印馬", "己巳": "本命印馬", "庚巳": "印殺馬", "辛巳": "印殺馬",
    "庚申": "本命馬", "辛申": "本命馬", "壬申": "印馬", "癸申": "印馬", "戊申": "食馬", "己申": "傷馬",
    "壬亥": "本命馬", "癸亥": "本命馬", "甲亥": "印馬", "乙亥": "印馬",
}
export function isHorse(selectedLetter, selectedLetterIndex, earthBranch, earthBranchIndex) {
    const house = mapHorse[selectedLetter + earthBranch]
    return { "result": house && house.length > 0, "text": house }
}


const map12LongLife = {
    "甲": {
        "子": undefined, "丑": "冠帶", "寅": "臨官", "卯": "帝旺", "辰": "衰", "巳": "病", "午": "死", "未": "墓庫", "申": "絕", "酉": "胎", "戌": "養", "亥": "長生"
    },
    "乙": {
        "子": undefined, "丑": "冠帶", "卯": "臨官", "寅": "帝旺", "辰": "衰", "巳": "病", "午": "死", "未": "墓庫", "申": "絕", "酉": "胎", "戌": "養", "亥": "長生"
    },
    "丙": {
        "寅": "長生", "卯": undefined, "辰": "冠帶", "巳": "臨官", "午": "帝旺", "未": "衰", "申": "病", "酉": "死", "戌": "墓庫", "亥": "絕", "子": "胎", "丑": "養"
    },
    "丁": {
        "寅": "長生", "卯": undefined, "辰": "冠帶", "午": "臨官", "巳": "帝旺", "未": "衰", "申": "病", "酉": "死", "戌": "墓庫", "亥": "絕", "子": "胎", "丑": "養"
    },
    "戊": {
        "寅": "長生", "卯": undefined, "辰": "冠帶", "巳": "臨官", "午": "帝旺", "未": "衰", "申": "病", "酉": "死", "戌": "墓庫", "亥": "絕", "子": "胎", "丑": "養"
    },
    "己": {
        "寅": "長生", "卯": undefined, "辰": "冠帶", "午": "臨官", "巳": "帝旺", "未": "衰", "申": "病", "酉": "死", "戌": "墓庫", "亥": "絕", "子": "胎", "丑": "養"
    },
    "庚": {
        "巳": "長生", "午": undefined, "未": "冠帶", "申": "臨官", "酉": "帝旺", "戌": "衰", "亥": "病", "子": "死", "丑": "墓庫", "寅": "絕", "卯": "胎", "辰": "養"
    },
    "辛": {
        "巳": "長生", "午": undefined, "未": "冠帶", "酉": "臨官", "申": "帝旺", "戌": "衰", "亥": "病", "子": "死", "丑": "墓庫", "寅": "絕", "卯": "胎", "辰": "養"
    },
    "壬": {
        "申": "長生", "酉": undefined, "戌": "冠帶", "亥": "臨官", "子": "帝旺", "丑": "衰", "寅": "病", "卯": "死", "辰": "墓庫", "巳": "絕", "午": "胎", "未": "養"
    },
    "癸": {
        "申": "長生", "酉": undefined, "戌": "冠帶", "子": "臨官", "亥": "帝旺", "丑": "衰", "寅": "病", "卯": "死", "辰": "墓庫", "巳": "絕", "午": "胎", "未": "養"
    }
}

/**
 * Return 12 長生
 * @param {*} selectedLetter 
 * @param {*} selectedLetterIndex 
 * @param {*} earthBranch 
 * @param {*} earthBranchIndex 
 * @returns text of 12 長生. If text == 沐浴, return undefined
 */
export function get12LongLife(selectedLetter, selectedLetterIndex, earthBranch, earthBranchIndex) {

    let text;
    if(selectedLetter == undefined || map12LongLife[selectedLetter] == undefined){
        text = ""    
    }else{
        text = map12LongLife[selectedLetter][earthBranch]
    }

    const salary = getSalary(selectedLetter, selectedLetterIndex, earthBranch, earthBranchIndex)
    if (salary) {
        text = salary
    }
    const dagger = getPositiveDagger(selectedLetter, selectedLetterIndex, earthBranch, earthBranchIndex)
    if (dagger) {
        text = dagger
    }
    return text
}

const mapPositiveDagger = {
    "甲": "卯",
    "乙": "寅",
    "丙": "午",
    "丁": "巳",
    "戊": "午丑未",
    "己": "巳辰戌",
    "庚": "酉",
    "辛": "申",
    "壬": "子",
    "癸": "亥",
}



const mapSalary = {
    "甲": "寅",
    "乙": "卯",
    "丙": "巳",
    "丁": "午",
    "戊": "巳辰戌",
    "己": "午丑未",
    "庚": "申",
    "辛": "酉",
    "壬": "亥",
    "癸": "子",
}

/**
 * 取得祿位
 * @param {*} selectedLetter 
 * @param {*} earthBranch 
 * @param {*} earthBranchIndex 
 */
export function getSalary(selectedLetter, selectedLetterIndex, earthBranch, earthBranchIndex) {
    let result = ""
    const value = mapSalary[selectedLetter]
    if (value !== undefined) {
        const isSalary = value.includes(earthBranch)
        if (isSalary) {
            if (earthBranchIndex === 3) {
                result = "建祿"
            } else if (earthBranchIndex === 5) {
                result = "專祿"
            } else if (earthBranchIndex === 3) {
                result = "歸祿"
            } else {
                result = "祿位"
            }
        }
    }
    return result
}
/**
 * 取得陽刃
 * @param {*} selectedLetter 
 * @param {*} selectedLetterIndex 
 * @param {*} earthBranch 
 * @param {*} earthBranchIndex 
 */
export function getPositiveDagger(selectedLetter, selectedLetterIndex, earthBranch, earthBranchIndex) {
    let result = ""
    const value = mapPositiveDagger[selectedLetter]
    if (value !== undefined) {
        const isPositiveDagger = value.includes(earthBranch)
        if (isPositiveDagger) {
            result = "陽刃"
        }
    }
    return result

}

/**
 * 是否陽天干
 * @param {} dayStemLetter 
 * @returns bool
 */
export function isPositiveSkyStem(dayStemLetter) {
    return ["甲", "丙", "戊", "庚", "壬"].includes(dayStemLetter)
}