import {instance} from './index.js'
//系统用户
const SYS_USERINFO = 'sys_userInfo'
//系统token
const SYS_TOKEN = 'sys_token'

/**
 * @author hanqi
 * @description 获取当前用户信息
 * @param
 * @returns {object}
 */
export function GetUserInfo() {
    let str = sessionStorage.getItem(SYS_USERINFO) || '';
    if (!str) return {};
    return JSON.parse(str)
}

/**
 * @author hanqi
 * @description 获取token
 * @param
 * @returns {string}
 */
export function GetToken() {
    return sessionStorage.getItem(SYS_TOKEN) || '';
}
/**
 * @author hanqi
 * @description 存储token
 * @param {string}
 * @returns
 */
export function SetToken(token) {
    sessionStorage.setItem(SYS_TOKEN, token);    
    instance.defaults.headers['token'] = token
}

export function RemoveToken() {
    sessionStorage.removeItem(SYS_TOKEN);
    sessionStorage.removeItem('userinfo');
    instance.defaults.headers['token'] = ''
}


