import React, { useEffect, useState } from 'react';
import styles from "./index.module.css";
import {
    parseDetailsOfLetterAddressToArray,
} from "../../Helper/AddressObj";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Draggable from "react-draggable";
import PropTypes from 'prop-types';
import _ from 'lodash';

export default function MsonSelector(props) {
    const [classOfLegend, setClassOfLegend] = useState(styles.legendContent);
    const [arrSwitch, setArrSwitch] = useState([]);
    const [minimizeButtonText, setMinimizeButtonText] = useState("最小化");
    const [title, setTitle] = useState("合化組合清單");
    const [allCheckboxState, setAllCheckboxState] = useState(true);

    useEffect(() => {
        if (props.horoscopeData) {
            setArrSwitch([]); // reset data
            parseCombinationToSwitch(props.horoscopeData);
          
        }
    }, [props.horoscopeData]);


    // useEffect(()=>{
    //     setTimeout(() => {
    //         onSwitchChangeAllToState(false)
    //         setAllCheckboxState(false)
    //     }, 3000);
    // },[])

    const onSwitchChange = (msonId) => {
        props.setMsonIdPartyShowList(prevMsonIdShowList => ({
            ...prevMsonIdShowList,
            [msonId]: { status: !prevMsonIdShowList[msonId].status, sentence: prevMsonIdShowList[msonId].sentence }
        }));
    };


    const onSwitchChangeAllToState = (switchNewState) => {

        if(props.msonIdPartyShowList){
        const updatedList = _.cloneDeep(props.msonIdPartyShowList);
        Object.keys(updatedList).forEach(key => {
            updatedList[key].status = switchNewState;
        });

        props.setMsonIdPartyShowList(updatedList);
        }
    };

    /**
     * parse horoscopeData into switch
     * @param {*} horoscopeData - the source of data from server
     */
    const parseCombinationToSwitch = (horoscopeData) => {
        if (horoscopeData && horoscopeData.letterAddressAllDate && Object.entries(horoscopeData.letterAddressAllDate).length > 0) {
            const entries = Object.entries(horoscopeData.letterAddressAllDate);
            let msonIdPartyShowListTemp = {};

            for (const [key, value] of entries) {
                if (value !== undefined) {
                    const details = value.details;
                    const arrDetailsObj = parseDetailsOfLetterAddressToArray(details, props.addressSchema);

                    arrDetailsObj.forEach((obj, i) => {
                        msonIdPartyShowListTemp[obj.msonId] = { status: true, sentence: obj.sentence }; // set default to show arrow
                    });
                }
            }
            props.setMsonIdPartyShowList(msonIdPartyShowListTemp);    //create this status object for render <switch>
        }
    };

    const toggleLegendContent = () => {
        setClassOfLegend(prevClass => {
            if (prevClass === styles.legendContent) {
                setMinimizeButtonText("打開");
                setTitle("")
                return styles.none
            } else {
                setTitle("合化組合清單")
                setMinimizeButtonText("最小化");
                return styles.legendContent
            }
        })
    };


    const isAnyBoxChecked = () => {
        const result = Object.keys(props.msonIdPartyShowList).reduce(
            (accumulator, currentValue) => accumulator || props.msonIdPartyShowList[currentValue].status, false
        )

        return result
    }

    const content = (
        <div>
            {/* <div className={styles.legendHeaderSelected} >圖例</div>  */}
            <div className={styles.legendHeader} >
                <div className={styles.gridContainerSingleRow3} >
                    <FormControlLabel key={"FormControlLabel_all"}
                        control={
                            <Switch key={"Switch_all"}
                                checked={allCheckboxState}
                                onChange={() => {
                                    const switchNewState = !allCheckboxState

                                    onSwitchChangeAllToState(switchNewState)

                                    setAllCheckboxState(switchNewState)
                                    // Object.keys(props.msonIdPartyShowList).map(item => ({
                                    //     ...item,
                                    //     status: !item.status,
                                    //   }));
                                    // Object.keys(props.msonIdPartyShowList).map((msonId, index) =>{
                                    //     console.log("onSwitchChangeToState ",!allCheckboxState)
                                    //     onSwitchChangeToState(msonId, !allCheckboxState)
                                    // })
                                    //setAllCheckboxState(!isAnyBoxChecked())

                                }}
                            />
                        }
                    />
                    <div className={styles.title}>{title}</div>

                    {props.showMinimizeButton && (<button className={styles.minButton} onClick={toggleLegendContent}>{minimizeButtonText}</button>)}
                </div>
            </div>


            {/* <div className={styles.legendHeader} >合化組合清單 <MinimizeIcon onClick={toggleLegendContent}/> </div> */}
            <div className={classOfLegend}>
                {/* <FormGroup>{arrSwitch}</FormGroup> */}

                {
                    props.msonIdPartyShowList && Object.keys(props.msonIdPartyShowList).length > 0 &&

                    Object.keys(props.msonIdPartyShowList).map((msonId, index) =>
                        <div key={"Div_FormControlLabel" + msonId} className={index % 2 === 0 ? styles.evenBg : styles.oddBg} >

                            {isNaN(msonId) ? <div style={{ fontSize: '12px' }}>{props.msonIdPartyShowList[msonId].sentence}</div> :
                                <FormControlLabel key={"FormControlLabel" + msonId}
                                    control={
                                        <Switch key={"Switch" + msonId}
                                            checked={props.msonIdPartyShowList[msonId].status}
                                            onChange={() => {
                                                onSwitchChange(msonId)
                                                //         setAllCheckboxState(!isAnyBoxChecked())
                                            }}

                                        />
                                    }
                                    label={
                                        <Typography style={{ fontSize: '12px' }}>
                                            {props.msonIdPartyShowList[msonId].sentence}
                                        </Typography>
                                    }
                                />
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )

    return props.shouldWrapWithDraggable ? <Draggable>{content}</Draggable> : content;
}

MsonSelector.propTypes = {
    horoscopeData: PropTypes.object,
    addressSchema: PropTypes.array.isRequired,
    setMsonIdPartyShowList: PropTypes.func.isRequired,
    msonIdPartyShowList: PropTypes.object,
    shouldWrapWithDraggable: PropTypes.bool,
    showMinimizeButton: PropTypes.bool,
};