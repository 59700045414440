import React, { useEffect, useState } from "react";
import styles from './index.module.css'
import { get10God } from "../../Helper/AddressObj";
import { isPeachBlossoms, isStudyHelper, isHorse, get12LongLife } from "../../Helper/LetterStatus";

export default function Letter(props) {

    //a list of extra info. hardcode index for each content
    //0: 十二長生, 1: 桃花, 2: 文昌貴人, 3: 驛馬
    const [arrExtraInfo, setArrExtraInfo] = useState(props.arrExtraInfo || []);

    //a list to show on UI. All content queued up one by one
    const [arrExtraInfoDisplayList, setArrExtraInfoDisplayList] = useState([]);

    useEffect(() => {
        if (props.selectedLetterIndex) {
            let arr = []
            if (!checkMyselfSkyStem()) {
                //case: 地支

                // index 0 十二長生
                arr[0] = get12LongLife(props.selectedLetter, props.selectedLetterIndex, props.mainText.name, props.index)
                // index 1 桃花
                const peachBlossomsResult = isPeachBlossoms(props.selectedLetter, props.selectedLetterIndex, props.mainText.name, props.index)
                if (peachBlossomsResult.result) {
                    arr[1] = (peachBlossomsResult.text)
                }

                // index 2 文昌貴人
                const studyHelperResult = isStudyHelper(props.selectedLetter, props.selectedLetterIndex, props.mainText.name, props.index)
                if (studyHelperResult.result) {
                    arr[2] = (studyHelperResult.text)
                }

                // index 3 驛馬
                const houseResult = isHorse(props.selectedLetter, props.selectedLetterIndex, props.mainText.name, props.index)
                if (houseResult.result) {
                    arr[3] = (houseResult.text)
                }
            }
            setArrExtraInfo(arr)
        }

    }, [props.selectedLetterIndex, props.mainText.name])


    useEffect(() => {

        let arr = []
        for (let i = 0; i < arrExtraInfo.length; i++) {
            if (shouldDisplay(i) && arrExtraInfo[i] !== undefined) {
                arr.push(arrExtraInfo[i])
            }
        }
        setArrExtraInfoDisplayList(arr)

    }, [props.extraInfoMask, props.selectedLetterIndex, arrExtraInfo])


    const shouldDisplay = (index) => {
        return props.extraInfoMask.length > index && props.extraInfoMask[index] === "1"
    }

    const getClassNameByProperty = (fiveProperty) => {
        if (fiveProperty === "金") return styles.Gold
        else if (fiveProperty === "水") return styles.Water
        else if (fiveProperty === "木") return styles.Wood
        else if (fiveProperty === "火") return styles.Fire
        else if (fiveProperty === "土") return styles.Earth
    }

    const onDivClick = (chi, chiPrefix) => {
        if (props.index !== undefined) {    //case: If props.Index is undefined, it is 排大運 的字. 
            props.setSelectedLetter(chi)
            props.setSelectedLetterIndex(props.index + "-" + chiPrefix)
        }
    }

    /**
     * Get whether this letter is selected
     * @returns false if this letter is not selected. return number if selected. O M or R if chi is selected
     */
    const isSelectedLetterIndexMyself = () => {
        if (props.selectedLetterIndex && props.selectedLetterIndex.length > 0) {    //1st is index, 2nd is -, 3 is chi
            const arr = props.selectedLetterIndex.split('-')
            if (arr[0] == props.index) {
                if (arr.length === 2) {
                    return arr[1]
                } else {
                    return arr[0]
                }
            }
        }
        return false

    }


    /**
     * 這個字是否天干
     * @returns bool
     */
    const checkMyselfSkyStem = () => {
        return !props.mainText.tenGod.originalChi
    }

    const getChiDiv = (chiPrefix) => {
        if (props.mainText) {
            const chiPrefixLookup = {
                "O": {
                    "property": props.mainText.tenGod.originalChiFiveProperty,
                    "chi": props.mainText.tenGod.originalChi
                },
                "M": {
                    "property": props.mainText.tenGod.middleChiFiveProperty,
                    "chi": props.mainText.tenGod.middleChi
                },
                "R": {
                    "property": props.mainText.tenGod.reminderChiFiveProperty,
                    "chi": props.mainText.tenGod.reminderChi
                }
            }

            const myselfOrChi = isSelectedLetterIndexMyself()
            const isMyselfSkyStem = checkMyselfSkyStem()
            const selectedChiPrefix = myselfOrChi && isNaN(myselfOrChi) ? myselfOrChi : undefined

            let classNameCSS = ""   //設定能否onClick 的style
            if (props.index === undefined) {
                //case: 用來排大運的字
                classNameCSS = `${getClassNameByProperty(chiPrefixLookup[chiPrefix].property)}`

            } else {
                //case: 用來分析的字
                classNameCSS = `${styles.chi} ${getClassNameByProperty(chiPrefixLookup[chiPrefix].property)}`

            }

            if (isMyselfSkyStem && myselfOrChi) {  //case: 天干 + 巳選擇
                return <div>{"\u00A0"}</div>
            } else if (isMyselfSkyStem && !myselfOrChi) {//case: 天干 + 沒選擇自己
                if (chiPrefix === "O") {
                    //onDivClick: 如果沒有氣, 即是天干, 把天干放入
                    return (<div className={classNameCSS}
                        onClick={() => onDivClick(props.mainText.name, chiPrefix)}>
                        {get10God(props.selectedLetter, props.mainText.name)}</div>
                    )
                } else {
                    //case: 天干沒餘氣
                    return <div>{"\u00A0"}</div>
                }
            } else if (!isMyselfSkyStem) {//case: 非日元 + 有主氣, 是地支
                if (chiPrefixLookup[chiPrefix].chi && chiPrefixLookup[chiPrefix].chi.trim().length > 0) {
                    return (<div className={classNameCSS}
                        onClick={() => onDivClick(chiPrefixLookup[chiPrefix].chi, chiPrefix)}>
                        {chiPrefixLookup[chiPrefix].chi}{selectedChiPrefix == chiPrefix ? "\u00A0" : get10God(props.selectedLetter, chiPrefixLookup[chiPrefix].chi)}</div>
                    )
                } else {
                    //cas: //沒有中氣或餘氣的情況, 就應移除onClick
                    return (<div>{"\u00A0"}</div>
                    )
                }
            }
        } else {
            return <div>{"\u00A0"}</div>
        }
    }




    return (
    <div className={`${props.halfHeight == true ? styles.letterGridSky : styles.letterGridEarth} ${props.border == "true" && styles.letterGridBorder}`}>

            {/* 1 */}
            <div className={styles.keyLetter}>{props.mainText ? props.mainText.name : ""}</div>

            {/* 2 */}
            {getChiDiv("O")}


            {/* 3 */}
            <div className={styles.extraInfo}>{arrExtraInfoDisplayList.length >= 1 ? arrExtraInfoDisplayList[0] : ""}</div>

            {/* 4 */}

            {getChiDiv("M")}
            {/* {props.mainText.tenGod ?
                <div className={getClassNameByProperty(props.mainText.tenGod.middleChiFiveProperty)}>{props.mainText.tenGod.middleChi}{props.mainText.tenGod.middleChiGod}</div>
                : <div className={styles.mainLetter}></div>
            } */}

            {/* 5 */}
            <div className={styles.extraInfo}>{arrExtraInfoDisplayList.length >= 2 ? arrExtraInfoDisplayList[1] : ""}</div>


            {/* 6 */}
            {getChiDiv("R")}
            <div className={styles.extraInfo} >{arrExtraInfoDisplayList.length >= 3 ? arrExtraInfoDisplayList[2] : ""}</div>
            <div className={styles.extraInfo}>{arrExtraInfoDisplayList.length >= 4 ? arrExtraInfoDisplayList[3] : ""}</div>
            <div className={styles.extraInfo}>{arrExtraInfoDisplayList.length >= 5 ? arrExtraInfoDisplayList[4] : ""}</div>
            <div className={styles.extraInfo}>{arrExtraInfoDisplayList.length >= 6 ? arrExtraInfoDisplayList[5] : ""}</div>



        </div>
    )
}