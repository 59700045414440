import React from "react";

import styles from './index.module.css'

export default function FiveElementCell(props){

    const getClassNameByProperty = (fiveProperty)=>{
        if(fiveProperty === "金") return styles.Gold
        else if(fiveProperty === "水") return styles.Water
        else if(fiveProperty === "木") return styles.Wood
        else if(fiveProperty === "火") return styles.Fire
        else if(fiveProperty === "土") return styles.Earth
    }

    return (
        <div className={getClassNameByProperty(props.element)}>{props.text}</div>
    );


}