import { useEffect, useState } from "react"
import YearFortuneLetter from "../YearFortuneLetter"
import './index.css'
import Letter from "../Letter"
import { getLetterAddressObjEarth,getLetterAddressObjSky } from "../../Helper/AddressObj";

//year
//age
//skyStem.name
//earthBranch.name
//selectedYear
//
export default function YearFortune1(props){

    const onDivClick = ()=>{
        if(props.onChildClicked){
            props.onChildClicked(props.yearfortune100Result,props.yearRange, props.year, props.fullDate, props.indexNumber)
        }
    }


    const cssSelector=()=>{
        let result;
        if(props.isClickable){
            //enabled click feature
            result = "YFcontainer cellHeight YFcontainerHover"
        }else{
            //not enabled click feature
            result = "YFcontainer cellHeight"
        }
        return result;
    }

    const getLetterAddressObjEarthWithChi = (dayStemLetter, letter, chi) =>{
        let originalChi = chi.length >= 1?chi[0]:""
        let middleChi= chi.length >= 2?chi[1]:""
        let reminderChi= chi.length == 3?chi[2]:""

        return getLetterAddressObjEarth(dayStemLetter, letter, originalChi, middleChi,reminderChi)
    }

    return (

        
        // if selectedYearIndexNumber != indexNumber, this year is not selected. It should be dimmed

        <div 
        className={cssSelector()}
         style={{opacity: 
            props.selectedYearIndexNumber != -1?(props.selectedYearIndexNumber !== props.indexNumber)?'0.5':'1' 
            :
            props.fullDate?(props.selectedFullDate != 0 && props.selectedFullDate !== props.fullDate)?'0.5':'1' 
            :"1", 
            // height:"100%"
        }}
        onClick={onDivClick}
        year={props.year}
        >
            {props.year &&
                <div>{props.year} ({props.age})</div>
            }

            {props.fullDate &&
                <div>{props.fullDate}</div>
            }

            {props.skyStem &&
            <Letter index={props.skyStemIndex}  mainText={getLetterAddressObjSky(props.selectedLetter, props.skyStem)} border="false"
            
            selectedLetterIndex={props.selectedLetterIndex} setSelectedLetterIndex={props.setSelectedLetterIndex}
            selectedLetter={props.selectedLetter} setSelectedLetter={props.setSelectedLetter} extraInfoMask={props.extraInfoMask} 
            halfHeight={true}
            />
            }
            {props.earthBranch &&
            <Letter index={props.earthBranchIndex} mainText={getLetterAddressObjEarthWithChi(props.selectedLetter,props.earthBranch, props.chi)} border="false"
            selectedLetterIndex={props.selectedLetterIndex} setSelectedLetterIndex={props.setSelectedLetterIndex}
            selectedLetter={props.selectedLetter} setSelectedLetter={props.setSelectedLetter} extraInfoMask={props.extraInfoMask} 
            />
        }
            </div>
    )
}