import React from "react"
export default function Error(){


    return (
<div>
    Error
</div>

    )

}