import React, { useEffect, useState } from "react";





export default function StartFortune(props) {


    const parseStartFortune = (horosopeResult, isMale) => {
        let result = ""
        if(horosopeResult.startFortuneInfo){

            result +="入運時間："+horosopeResult.startFortuneInfo.startFortuneDate.replace("T"," ")+"\r\n"
            result +="大運排列："
            if(horosopeResult.startFortuneInfo.isSequencialOrder === true ){
                if(isMale === true){
                    result += "陽男順行"
                }else{
                    result += "陰女順行"
                }
            }else{
                if(isMale === true){
                    result += "陰男逆行"
                }else{
                    result += "陽女逆行"
                }
            }
            result += "\r\n"

            result +="上一節氣："+horosopeResult.startFortuneInfo.previousFestivalName+" " +horosopeResult.startFortuneInfo.previousFestivalDateTime.replace("T"," ")+"\r\n"
            result +="下一節氣："+horosopeResult.startFortuneInfo.nextFestivalName+" " +horosopeResult.startFortuneInfo.nextFestivalDateTime.replace("T"," ")+"\r\n"
             
        }
        return result
    }

    return (<>
       <textarea className={props.className} readOnly value={parseStartFortune(props.horosopeResult, props.isMale)}>
        </textarea>
    
    </>
        
    )

}