import './App.css';
import axios from 'axios';
import React, { useEffect, useState, useRef } from "react";
import EightLettersPage from '../component/EightLettersPage';

function App() {

  const solarTimeURL = "https://fate.windada.com/cgi-bin/SolarTime";
  const [iFrameSrc, setiFrameSrc] = useState(solarTimeURL);
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);
  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(0);
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  const formRef = useRef(null);

  const getSolarTime = (dateString) => {
    const dateObj = new Date(dateString)
    setYear(dateObj.getFullYear());
    setMonth(dateObj.getMonth() + 1);
    setDay(dateObj.getDate());
    setHour(dateObj.getHours());
    setMinute(dateObj.getMinutes());
    setSecond(dateObj.getSeconds());
  }

  //submit form after updating time and called getSolarTime()
  useEffect(() => {
    if(year != 0){
      formRef.current.submit();
      setIsDetailOpen(true)
    }
  },[year,month,day,hour,minute,second]);

  return (
    <>
  <div className="App">
        <EightLettersPage getSolarTime={getSolarTime} />
      </div>
      {year != 0?
      <details className='iFrameDetails' open={isDetailOpen} >
        <summary className='detailTitle'>太陽時 <button onClick={() => setiFrameSrc(solarTimeURL + "?t=" + new Date().getTime())}>首頁</button> </summary>
        <iframe title="suntime" name="iFrame" id="iFrame" className='iFrame' src={iFrameSrc} />

        <form ref={formRef} action="https://fate.windada.com/cgi-bin/SolarTime" method="post" target="iFrame">
          <input type="hidden" name="FUNC" value="BDayInfo" />
          <input type="hidden" name="latitude" value="22.316700" />
          <input type="hidden" name="longitude" value="114.183296" />
          <input type="hidden" name="TZName" value="Asia/Hong_Kong" />
          <input type="hidden" name="TZ" value="8" />
          <input type="hidden" name="Year" value={year} />
          <input type="hidden" name="Month" value={month} />
          <input type="hidden" name="Day" value={day} />
          <input type="hidden" name="Hour" value={hour} />
          <input type="hidden" name="Min" value={minute} />
          <input type="hidden" name="Sec" value={second} />
        </form>
      </details>
      :""}
    </>
  );
}


export default App;
