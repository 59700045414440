import * as React from 'react';
import { useState, useEffect } from 'react';
import styles from "./index.module.css"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { get } from '../../axios'


export default function SolarTimeSelector(props) {

    // const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [solarTime, setSolarTime] = useState();
    const [birthday, setBirthday] = useState(props.birthday);
    const [city, setCity] = useState("香港");


    useEffect(() => {
        setBirthday(props.birthday)
    }, [props.openSolarTime])

    useEffect(() => {
        if (props.openSolarTime && city && birthday) {
            get(`api/birthday/solartime/${birthday}/city/${city}?t=${Date.now()}`).then(res => {
                if (res.data && res.data.success) {
                    if (res.data.data && res.data.data.trueSolarTime) {
                        setSolarTime(res.data.data.trueSolarTime)
                    } else {
                        console.log("no trueSolarTime")
                    }
                }
            })
        }
    }, [city, birthday,props.openSolarTime])

    const cityTime = [
        { "city": "香港", "latitude": 22.3193, "longitude": 114.1694, "country": "中國", "timeZone": 8 },
        { "city": "廣州", "latitude": 23.1291, "longitude": 113.2644, "country": "中國", "timeZone": 8 },
        { "city": "大連", "latitude": 38.9140, "longitude": 121.6147, "country": "中國", "timeZone": 8 },
        { "city": "廈門", "latitude": 24.4798, "longitude": 118.0894, "country": "中國", "timeZone": 8 },
        { "city": "北京", "latitude": 39.9042, "longitude": 116.4074, "country": "中國", "timeZone": 8 },
        { "city": "上海", "latitude": 31.2304, "longitude": 121.4737, "country": "中國", "timeZone": 8 },
        { "city": "深圳", "latitude": 22.5431, "longitude": 114.0579, "country": "中國", "timeZone": 8 },
        { "city": "天津", "latitude": 39.3434, "longitude": 117.3616, "country": "中國", "timeZone": 8 },
        { "city": "重慶", "latitude": 29.4316, "longitude": 106.9123, "country": "中國", "timeZone": 8 },
        { "city": "澳門", "latitude": 22.1987, "longitude": 113.5439, "country": "中國", "timeZone": 8 },
        { "city": "武漢", "latitude": 30.5928, "longitude": 114.3055, "country": "中國", "timeZone": 8 },
        { "city": "成都", "latitude": 30.5728, "longitude": 104.0668, "country": "中國", "timeZone": 8 },
        { "city": "西安", "latitude": 34.3416, "longitude": 108.9398, "country": "中國", "timeZone": 8 },
        { "city": "杭州", "latitude": 30.2741, "longitude": 120.1551, "country": "中國", "timeZone": 8 },
        { "city": "南京", "latitude": 32.0603, "longitude": 118.7969, "country": "中國", "timeZone": 8 },
        { "city": "瀋陽", "latitude": 41.8057, "longitude": 123.4328, "country": "中國", "timeZone": 8 },
        { "city": "青島", "latitude": 36.0671, "longitude": 120.3826, "country": "中國", "timeZone": 8 },
        { "city": "昆明", "latitude": 25.0389, "longitude": 102.7183, "country": "中國", "timeZone": 8 },
        { "city": "哈爾濱", "latitude": 45.8038, "longitude": 126.5349, "country": "中國", "timeZone": 8 },
        { "city": "長沙", "latitude": 28.2282, "longitude": 112.9388, "country": "中國", "timeZone": 8 },
        { "city": "首爾", "latitude": 37.5665, "longitude": 126.9780, "country": "韓國", "timeZone": 9 },
        { "city": "台北", "latitude": 25.0330, "longitude": 121.5654, "country": "台灣", "timeZone": 8 },
        { "city": "新加坡", "latitude": 1.3521, "longitude": 103.8198, "country": "新加坡", "timeZone": 8 },
        { "city": "吉隆坡", "latitude": 3.1390, "longitude": 101.6869, "country": "馬來西亞", "timeZone": 8 },
        { "city": "曼谷", "latitude": 13.7563, "longitude": 100.5018, "country": "泰國", "timeZone": 7 },
        { "city": "東京", "latitude": 35.6895, "longitude": 139.6917, "country": "日本", "timeZone": 9 },
        { "city": "悉尼", "latitude": -33.8688, "longitude": 151.2093, "country": "澳大利亞", "timeZone": 10 },
        { "city": "倫敦", "latitude": 51.5074, "longitude": -0.1278, "country": "英國", "timeZone": 0 },
        { "city": "巴黎", "latitude": 48.8566, "longitude": 2.3522, "country": "法國", "timeZone": 1 },
        { "city": "柏林", "latitude": 52.5200, "longitude": 13.4050, "country": "德國", "timeZone": 1 },
        { "city": "莫斯科", "latitude": 55.7558, "longitude": 37.6173, "country": "俄羅斯", "timeZone": 3 },
        { "city": "紐約", "latitude": 40.7128, "longitude": -74.0060, "country": "美國", "timeZone": -5 },
        { "city": "新德里", "latitude": 28.6139, "longitude": 77.2090, "country": "印度", "timeZone": 5.5 },
        { "city": "開普敦", "latitude": -33.9249, "longitude": 18.4241, "country": "南非", "timeZone": 2 },
        { "city": "里約熱內盧", "latitude": -22.9068, "longitude": -43.1729, "country": "巴西", "timeZone": -3 }
    ]


    const handleDateChange = (e) => {
        if (!e.target['validity'].valid) return;
        setBirthday(e.target['value'])

        //for checking birthday and filter outdated server reply

    }


    const handleClose = () => {
        props.setOpenSolarTime(false);
    };

    const handleCityChange = (event) => {
        setCity(event.target.value); // Update the selected value
    };

    const confirmUsingSolarTime = () => {
        props.setBirthday(solarTime.split('.')[0])
        handleClose()
    }




    return <>
        {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open responsive dialog
      </Button> */}
        <Dialog
            // fullScreen={fullScreen}
            open={props.openSolarTime}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {"查詢太陽時"}
            </DialogTitle>
            <DialogContent>
                <div className={styles.gridContainer2Columns}>
                    <div>出生日期</div>
                    <div>

                        <input type="datetime-local"
                            className="inputDatetime"
                            value={birthday || ''}
                            onChange={handleDateChange}
                        />

                    </div>
                    <div>出生地點</div>
                    <div>
                        <select onChange={handleCityChange}>
                            {cityTime.map((location, index) => (
                                <option key={index} value={location.city}>
                                    {`${location.country} - ${location.city}`}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>真太陽時</div>
                    <div>

                        <input type="datetime-local"
                            className="inputDatetime"
                            value={solarTime || ''}
                            disabled={true}
                        />
                    </div>

                </div>


            </DialogContent>
            <DialogActions>
                <button autoFocus onClick={handleClose}>
                    取消
                </button>
                <button onClick={confirmUsingSolarTime} disabled={solarTime === undefined}>
                    使用太陽時
                </button>
            </DialogActions>
        </Dialog>

    </>

}


SolarTimeSelector.propTypes = {
    openSolarTime: PropTypes.any.isRequired, // replace 'any' with the expected type of 'status1'
    setOpenSolarTime: PropTypes.any.isRequired,
    birthday: PropTypes.any,
    setBirthday: PropTypes.any.isRequired
}