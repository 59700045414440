import React, { useEffect, useState } from "react";
import styles from "./index.module.css"
import Xarrow, { useXarrow, Xwrapper } from 'react-xarrows';
import { arrowStyles } from "../EightLetters";

export default function ArrowLegend(props) {


    const drawLegend = () => {
        let result = []
        let arrows = []

        let drawnPartyDistend = false
        let i = 0
        Object.keys(arrowStyles).forEach((key) => {

            let firstPartyDistendArrow = key.indexOf("局拱") >= 0 && !drawnPartyDistend
            if ((key.indexOf("局拱") === -1 && key.indexOf("遇龍則化") === -1) || firstPartyDistendArrow) {

                const idArrowStart = "arrowStart" + i+ +Date.now()
                const idArrowEnd = "arrowEnd" + i+ +Date.now()


                result.push(<div id={idArrowStart} key={idArrowStart}
                    className={styles.startArrowDiv}></div>)

                result.push(<div id={idArrowEnd} key={idArrowEnd}
                className={styles.endArrowDiv} ></div>)

                arrows.push(<Xarrow
                    
                    key={"arrow" + i + Date.now()}
                    start={idArrowStart} // the id of the starting element
                    end={idArrowEnd} // the id of the ending element
                    color={arrowStyles[key].color} // color of the arrow
                    strokeWidth={arrowStyles[key].strokeWidth} // thickness of the line
                    headSize={10} // the size of the arrow head
                    tailSize={5} // the size of the arrow head
                    tailShape='circle'
                    showTail
                    showHead // show an arrow at the end
                    startAnchor={{ position: "left", offset: { x: 0 } }}
                    endAnchor={{ position: "auto", offset: { x: 0 } }}
                    labels={firstPartyDistendArrow ? "合局拱" : key}
                />)

                //don't repeat all 局拱, draw once
                if (firstPartyDistendArrow) {
                    drawnPartyDistend = true
                }
            }


            i++

        })

        return <><div className={styles.gridContainer2Columns}>{result}</div><div>{arrows}</div></>
    }



    return (
        <>
            <div className={styles.tabsContainer}>
                <div className={styles.tabsHeader}>圖例</div>
                <div>
                    {drawLegend()}
                </div>
            </div>
        </>

    )

}