import axioses from "axios";
// import { notification } from 'tea-component'
// import Alert from '../components/alert';
import { GetToken, RemoveToken } from './storage'
//对外提供的服務地址
// export const BaseURL =
//     process.env.NODE_ENV === 'development' ? 'http://localhost:88888/api' : '/'

export const SYS_TOKEN = 'sys_token'

export const instance = axioses.create({
    timeout: 25000, // 超时时间
    // baseURL: axios.defaults.baseURL
})
// instance.defaults.baseURL = axios.defaults.baseURL
instance.defaults.headers.post['Content-Type'] = 'application/json'
if (GetToken()) {
    instance.defaults.headers['token'] = GetToken()
}

export const axios = instance

/* 统一封装get請求 */
export const get = (url, params, config = {}) => {
    return new Promise((resolve, reject) => {
        instance({
            method: 'get',
            url,
            params,
            ...config
        }).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

/* 统一封装post請求  */
export const post = (url, data, config = {}) => {
    return new Promise((resolve, reject) => {
        instance({
            method: 'post',
            url,
            data,
            ...config
        }).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}