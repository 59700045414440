import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

/**
 * 祿位
 * Test http://localhost:3000/inputDate/1927-03-22T05:01:00/ismale/true
 * @param {*} props 
 * @returns 
 */
export default function Salary(props) {


    const parseSalaryAnalysis = (analysisResult) => {
        let result = ""
        const salary = analysisResult.salaryResult.keyValuePairs

        if(salary){

            for(const [key,value] of Object.entries(salary)){
            result += `${key} ${ value?": "+value:""}\r\n`

            }
        }
        return result==""?"並無組合":result
    }

    return (<>
        <textarea className={props.className} readOnly value={parseSalaryAnalysis(props.analysisResult)}>
        </textarea>
    </>)

}



Salary.propTypes = {
    className: PropTypes.string,
    analysisResult: PropTypes.any.isRequired, // replace 'any' with the expected type of 'status1'
    endOriginal8Letter: PropTypes.number.isRequired,
    addressSchema: PropTypes.any.isRequired
}