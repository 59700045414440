import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './layouts/App';
import {axios} from "./axios"
import {
  BrowserRouter, Routes,
  Route,Navigate
} from "react-router-dom";
// import Setting from './view/Setting'
import Error from './view/Error';


//read config file for environment setup
fetch(process.env.PUBLIC_URL + "/config.json")
  .then(res => res.json())
  .then(config => {

    let clientURL = "";
    global.clientURL = "";
    //environment URL setting
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      axios.defaults.baseURL = config.developmentBaseURL;
      axios.defaults.timeout = config.requestTimeout;
    } else {
      axios.defaults.baseURL = config.productionBaseURL;
      axios.defaults.timeout = config.requestTimeout;
      clientURL = config.productionClientURL;
      global.clientURL = clientURL;
    }


  

    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      // <React.StrictMode>
        <BrowserRouter>
          <Routes>
            <Route  path={clientURL+"/inputDate/:inputDate/ismale/:isMale"} element={<App />} >
            </Route>
            <Route path="/" element={<Navigate to={clientURL+"inputDate/0/isMale/true"} />} />
            <Route path="*" element={<Error />} />
          </Routes>
          </BrowserRouter>
      // </React.StrictMode> 
    );

  });
