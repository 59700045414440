import React from "react";
import styles from './index.module.css'
import {isPositiveSkyStem} from "../../../Helper/LetterStatus"

export default function RelationTable(props) {
    return createRelationTable(props.isMale, props.dayStemLetter)
}


const createRelationTable = (isMale, dayStemLetter) => {
    const relationByGender = getRelationByGender(isMale, dayStemLetter)
    return (
        <div className={styles.table}>
            <div className={`${styles.row} ${styles.header}`}>
                <div className={styles.cell}>十神</div>
                <div className={styles.cell}>關係</div>
            </div>
            {Object.entries(relationByGender).map(([key, value]) => (
                <div className={styles.row} key={key}>
                    <div className={styles.cell}>{key}</div>
                    <div className={styles.cell}>{value}</div>
                </div>
            ))}
        </div>
    );
}


const getRelationByGender = (isMale, dayStemLetter) => {

    let isPositive = isPositiveSkyStem(dayStemLetter)

    const lookup = {} //lookup[isMale][isPositive]
    lookup["0"] = {}
    lookup["1"] = {}

    //TODO 外父, 外母、老爺、奶奶、

    //陽男 
    lookup["1"]["1"] = {
        "印": "媽媽、公司、老闆、導師、長輩、貴人、主流宗教",
        "ㄗ": "祖父、公司、老闆、導師、長輩、貴人、非主流宗教",
        "比": "兄弟、男性工作夥伴、男性朋友、同行、競爭對手",
        "劫": "姐妹、女性工作夥伴、女性朋友、同行、競爭對手",
        "食": "外祖父、學生、下屬、晚輩、寵物",
        "傷": "祖母、學生、下屬、晚輩、寵物",
        "才": "爸爸、僱員",
        "財": "女朋友、老婆、情婦、僱員",
        "官": "女兒、外祖母、上司、司司法官員員、政府機構、領導者",
        "殺": "兒子、小人、上司、軍隊、警務人員、靈異生物、犯罪組織"
    }

    //陽女 
    lookup["0"]["1"] = {
        "印": "母親、公司、老闆、導師、長輩、貴人、主流宗教",
        "ㄗ": "祖父、公司、老闆、導師、長輩、貴人、非主流宗教",
        "比": "兄弟、女性工作夥伴、女性朋友、同行、競爭對手",
        "劫": "姐妹、男性工作夥伴、男性朋友、同行、競爭對手",
        "食": "外祖父、兒子、學生、下屬、晚輩、寵物",
        "傷": "女兒、祖母、學生、下屬、晚輩、寵物",
        "財": "僱員",
        "才": "父親、僱員",
        "官": "丈夫、外祖母、上司、司法官員、政府機構、領導者",
        "殺": "小人、上司、軍隊、警務人員、靈異生物、犯罪組織"
    }
    //陰男
    lookup["1"]["0"] = {
        "印": "祖父、公司、老闆、導師、長輩、貴人、主流宗教",
        "ㄗ": "母親、公司、老闆、導師、長輩、貴人、非主流宗教",
        "比": "姐妹、女性工作夥伴、女性朋友、同行、競爭對手",
        "劫": "兄弟、男性工作夥伴、男性朋友、同行、競爭對手",
        "食": "祖母、學生、下屬、晚輩、寵物",
        "傷": "外祖父、學生、下屬、晚輩、寵物",
        "才": "女朋友、老婆、二奶、僱員",
        "財": "父親、僱員",
        "官": "兒子、上司、司法官員、政府機構、領導者",
        "殺": "女兒、外祖母、小人、上司、軍隊、警務人員、靈異生物、犯罪組織"
    }

    //陰女
    lookup["0"]["0"] = {
        "印": "祖父、公司、老闆、導師、長輩、貴人、主流宗教",
        "ㄗ": "母親、公司、老闆、導師、長輩、貴人、非主流宗教",
        "比": "姐妹、女性工作夥伴、女性朋友、同行、競爭對手",
        "劫": "兄弟、男性工作夥伴、男性朋友、同行、競爭對手",
        "食": "女兒、祖母、學生、下屬、晚輩、寵物",
        "傷": "外祖父、兒子、學生、下屬、晚輩、寵物",
        "財": "父親、僱員",
        "才": "僱員",
        "官": "丈夫、上司、司法官員、政府機構、領導者",
        "殺": "小人、外祖母、上司、軍隊、警務人員、靈異生物、犯罪組織"
    }

    return lookup[isMale ? "1" : "0"][isPositive ? "1" : "0"]
}