import React, { useEffect, useState } from "react";
import { get } from '../../../axios'
import styles from './index.module.css'


export default function UseGod(props) {

    return (
        <>
            <div className={`${styles.tableContainer}`}>
                <div className={`${styles.tableRow}`}>
                <div className={`${styles.tableCell}`}>{props.description || '用神'}</div>


                    <div className={`${styles.tableCell}`}>
                        <select value={props.useGod1} onChange={(event) => { props.setUseGod1(event.target.value) }} className={`${styles.comboBox}`}>
                            <option value=""> </option>
                            <option value="甲">甲</option>
                            <option value="乙">乙</option>
                            <option value="丙">丙</option>
                            <option value="丁">丁</option>
                            <option value="戊">戊</option>
                            <option value="己">己</option>
                            <option value="庚">庚</option>
                            <option value="辛">辛</option>
                            <option value="壬">壬</option>
                            <option value="癸">癸</option>
                        </select>
                    </div>
                    <div className={`${styles.tableCell}`}>

                        <select value={props.useGod2} onChange={(event) => { props.setUseGod2(event.target.value) }} className={`${styles.comboBox}`}>

                            <option value=""> </option>
                            <option value="甲">甲</option>
                            <option value="乙">乙</option>
                            <option value="丙">丙</option>
                            <option value="丁">丁</option>
                            <option value="戊">戊</option>
                            <option value="己">己</option>
                            <option value="庚">庚</option>
                            <option value="辛">辛</option>
                            <option value="壬">壬</option>
                            <option value="癸">癸</option>
                        </select>

                    </div>
                    <div className={`${styles.tableCell}`}>
                        <button onClick={props.onClick}>查詢</button>
                    </div>
                </div>
            </div>
            {/* {useGod1}
            {useGod2} */}

        </>
    )

}