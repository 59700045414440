import React, { useEffect } from "react";
import styles from "./index.module.css";
import Switch from '@mui/joy/Switch';
import Typography from '@mui/joy/Typography';

export default function GenderSelection(props) {


    return (

        <>
            <Switch
                checked={props.isMale}
                onChange={(event) => {
                    props.setIsMale(event.target.checked);
                }}
                slotProps={{
                    track: {
                        children: (
                            <React.Fragment>
                                <Typography component="span" level="inherit" sx={{ ml: '8px' }} >
                                    男
                                </Typography>
                                <Typography component="span" level="inherit" sx={{ mr: '8px' }}>
                                    女
                                </Typography>
                            </React.Fragment>
                        ),
                    },
                }}

                sx={{
                     '--Switch-thumbSize': '21px',
                    '--Switch-trackWidth': '50px',
                    '--Switch-trackBackground': props.isMale ? 'blue' : '#ef4567',
                    '--Switch-thumbColor': props.isMale ? 'blue' : '#ef4567',
                }}
            />

            {/* <>
                <input type="radio" value="Male" name="gender" checked={props.isMale === true}
                    disabled={props.disabled}
                onChange={(event) => { props.setIsMale(event.target.value === "Male") }} /><span className={styles.genderText} style={{ color: props.disabled ? "gray" : "black" }}> 男</span>
            </><>
                <input type="radio" value="Female" name="gender" checked={props.isMale === false}
                    disabled={props.disabled}
                    onChange={(event) => { props.setIsMale(event.target.value === "Male") }} /><span className={styles.genderText} style={{ color: props.disabled ? "gray" : "black" }}> 女</span>
            </>  */}
        </>
    )
}