import React, { useEffect, useState } from "react";
import Letter from "../Letter"

/**
 * 拱,合, 組合出來的Letter
 * @param {*} props 
 * @returns 
 */
export default function LetterDeviated(props) {

    return (
        <div> 
            <Letter index={props.index} mainText={props.mainText} border={props.mainText?"true":"false"}
                selectedLetterIndex={props.selectedLetterIndex} setSelectedLetterIndex={props.setSelectedLetterIndex}
                selectedLetter={props.selectedLetter} setSelectedLetter={props.setSelectedLetter} extraInfoMask={props.extraInfoMask}
                />
    </div>
    )
}