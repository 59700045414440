import ReactGA from 'react-ga4'
import { isProduction } from '../General'
// import { useLocation } from 'react-router-dom';



export const initializeAnalytics = () =>{
    if(isProduction()){
        //record real users
        ReactGA.initialize('G-KLFY7F5CBL')
    }else{
        //for debugging
        ReactGA.initialize('G-0D2XW8CM41')

    }
}
/**
 * Send {hitType:'pageView',eventCategory:location.pathname}
 */
// export const sendGAPageViewCurrentPage = ()=>{
//     const location = useLocation()
//       ReactGA.send({hitType:'pageview',eventCategory:location.pathname})
// }

/**
 * Send {hitType:'pageView',eventCategory:<Parameter>}
 */
export const sendGAPageView = (locationString)=>{
    ReactGA.send({hitType:'pageview',eventCategory:locationString})
}
