import React from "react";
import './index.css'
import { useState, useEffect } from 'react';
import EightLettersToWestenDate from "../EightLettersToWestenDate";
import GenderSelection from "../GenderSelection";
import { globalData } from "../../Helper/global";
import SolarTimeSelector from "../SolarTimeSelector";

export default function ClientDataInput(props) {

    const [activeTab, setActiveTab] = useState(1);
    const [birthday, setBirthday] = useState();
    const [version, setVersion] = useState(process.env.REACT_APP_VERSION);
    const [openSolarTime, setOpenSolarTime] = useState(false);

    //get version number


    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };


    const handleDateChange = (e) => {
        if (!e.target['validity'].valid) return;
        setBirthday(e.target['value'])
        //for checking birthday and filter outdated server reply

        if (!props.requireDateComfirmation) { // require date confirmation, so won't query to server when date change.
            globalData.inputDate = e.target['value']
            props.setInputDate(e.target['value'])
            // props.getBirthDate(e.target['value'])
        }
    }

    const confirmBirthday = () => {  //when user click confirmation, update birthday to parent to query server 
        globalData.inputDate = birthday
        props.setInputDate(birthday)
    }

    return (
        <>
            <SolarTimeSelector
                openSolarTime={openSolarTime} setOpenSolarTime={setOpenSolarTime}
                birthday={birthday || props.inputDate} setBirthday={setBirthday}
            />

            <div className="tabs-container">
                <div className="tabs-header">
                    <button
                        className={activeTab === 1 ? 'active-tab' : ''}
                        onClick={() => handleTabClick(1)}
                    >
                        西曆出生日期
                    </button>
                    <button
                        className={activeTab === 2 ? 'active-tab' : ''}
                        onClick={() => handleTabClick(2)}
                    >
                        時辰八字
                    </button>
                </div>
                <div className="tab-content">
                    {activeTab === 1 && (
                        <>
                            <div className="grid-container">
                                <div>請輸入西曆出生日期</div>
                                <div>&nbsp;</div>
                                <div>&nbsp;</div>
                                <div style={{ textAlign: "right" }}>
                                    <button onClick={() => {
                                        //props.getSolarTime(birthday)
                                        setOpenSolarTime(true)
                                    }}>轉換真太陽時</button>
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="grid-cell">(查詢前,請轉換真太陽時)</div>
                                <div>&nbsp;</div>
                            </div>
                            <div className="grid-container">
                                <div className="grid-cell">
                                    日期：<input type="datetime-local"
                                        className="input-datetime"
                                        value={birthday || props.inputDate || ''}
                                        onChange={handleDateChange}
                                    />
                                </div>
                                <div>&nbsp;</div>
                                <div>&nbsp;</div>

                                <div className="grid-cell">
                                    <GenderSelection setIsMale={props.setIsMale} isMale={props.isMale} />
                                </div>
                            </div>

                            <div style={{ textAlign: "right", fontSize: "10px" }}
                                onClick={() => { setVersion(window.innerWidth) }}
                            >Adacs v{version}</div>

                            {props.requireDateComfirmation &&
                                <button disabled={birthday === undefined || birthday.length === 0}
                                    onClick={() => { confirmBirthday() }} >查詢</button>}
                        </>

                    )}
                    {activeTab === 2 && (
                        <>
                            <EightLettersToWestenDate
                                // getBirthDate={props.getBirthDate}
                                setInputDate={props.setInputDate}
                                isMale={props.isMale} setIsMale={props.setIsMale} />
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
