
/**
 * Compare string for birthday
 * Expected variation:
 * 1922-07-05 01:09
 * 1922-07-05 01:09:00
 * 1922-07-05T01:09
 * 1922-07-05T01:09:00
 * 
 * @param {string} birthday1 
 * @param {string} birthday2 
 * @returns whether 2 birthday are same
 */
export function compareBirthday(birthday1, birthday2) {

    if (!birthday1 || !birthday2) {
        return false
    }

    birthday1 = birthday1.replace('T', " ")
    birthday2 = birthday2.replace('T', " ")
    return birthday1.substring(0, 16) == birthday2.substring(0, 16)
}


/**
 *  Get max. even numbers .
 * @param {*} array integer array
 * @returns the largest even number from array
 */
export function getMaxEvenNumber(array) {
    const allEvenNo = array.filter(num => num % 2 === 0)
    return Math.max(...allEvenNo)
}

/**
 *  Get max. Odd numbers .
 * @param {*} array integer array
 * @returns the largest even number from array
 */
export function getMaxOddNumber(array) {
    const allOddNo = array.filter(num => num % 2 !== 0)
    return Math.max(...allOddNo)
}

/**
 * Check whether intAddress >36 and isEven/isOdd. 36 is the max. seat
 * @param {*} seatNo the integer of address
 * @param {*} isEven 1 is even, 0 is odd
 */
export function isSeatExtra(seatNo, isEven) {
    let result = false
    if (seatNo > 36) {
        if (isEven) {
            result = (seatNo % 2 === 0)
        } else {
            result = (seatNo % 2 !== 0)
        }
    }
    return result
}